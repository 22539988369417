import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { environment } from '../../../../src/environments/environment';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-contact-us-copy',
	templateUrl: './contact-us-copy.component.html',
	styleUrls: ['./contact-us-copy.component.scss'],
})
export class ContactUsCopyComponent implements OnInit {
	langSub: Subscription;
	lang$: Observable<string>;

	@Input('isMobileView') isMobileView: boolean = false;
	@Input('form') form: any = null;
	@Input('contactBody') contactBody: string = 'Ob per E-Mail oder Telefon - wir freuen uns auf Deine Anfrage.';
	@Input('location') location: string = 'SV (Deutschland) GmbH, Elisabeth-Selbert-Str. 13, D-40764 Langenfeld';
	@Input('phone') phone: string = '+49 2173 2077 0';
	@Input('email') email: string = 'info@sv-group.de';
	@Input('coordinate') coordinate: { lat: number; lng: number } = { lat: 47.448948, lng: 8.567236 };
	selectedLanguage: string =
		localStorage.getItem('landing-page-language') != undefined ? localStorage.getItem('landing-page-language') : 'de';

	titleForm: any = null;
	contactBodyForm: any = null;
	locationForm: string = null;
	phoneForm: string = null;
	mailForm: string = null;
	latitudeForm: any = null;
	longitudeForm: any = null;
	isCookieConsentAllow = true;
	private _unSubscribeAll: Subject<any>;
	private statusChangeSubscription!: Subscription;
	map: google.maps.Map;

	clickPosition: any = null;
	outletName: string;
	mapLinkText: string = '';
	locationNotFoundText: string = '';
	getTranslations$: Observable<any>;
	currentMicroSiteLanguage: string = 'de';
	sanitizedUrl: SafeResourceUrl;
	constructor(
		private sanitizer: DomSanitizer,
		private appSectionTemplateService: AppSectionTemplateService,
		private translationService: TranslateService // @Inject(DOCUMENT) private _document: any, // private ccService: NgcCookieConsentService, // private cookieService: SsrCookieService
	) {
		this._unSubscribeAll = new Subject();
		this.translationService.use(this.selectedLanguage);
		// this.lang$ = this._store.select(getCurrentLanguage);
	}

	ngOnInit(): void {
		// this.langSub = this.lang$.subscribe((lang) => this.translationService.use(lang));
		this.currentMicroSiteLanguage = this.translationService.currentLang;
		this.getTranslations$ = this.translationService.get(['VIEW_IN_GOOGLE_MAPS', 'LOCATION_NOT_FOUND']).pipe(
			takeUntil(this._unSubscribeAll),
			distinctUntilChanged(),
			tap((res) => {
				this.selectedLanguage = this.currentMicroSiteLanguage;
				this.mapLinkText = res['VIEW_IN_GOOGLE_MAPS'];
				this.locationNotFoundText = res['LOCATION_NOT_FOUND'];
				this.proccessContactData();
			})
		);
	}

	onEnableMap() {
		this.isCookieConsentAllow = true;
		//this.cookieService.set('cookieconsent_status', 'allow', 2, '/', environment.cookieDomain);
	}

	proccessContactData() {
		if (this.form != null) {
			this.outletName = this.form.OutletMetadata.value.OutletName;
			this.contactBodyForm = this.form.OutletMetadata?.value?.ContactUsBodyText;
			this.locationForm = this.form.OutletMetadata?.value?.HoldingAddress;
			this.phoneForm = this.form.OutletMetadata?.value?.OutletPhoneNumber;
			this.mailForm = this.form?.OutletMetadata?.value?.OutletEmail;
			this.latitudeForm = this.form.OutletMetadata?.value?.GeolocationLatitude;
			this.longitudeForm = this.form.OutletMetadata?.value?.GeolocationLongitude;
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'contact-us');
		}

		if (this.contactBodyForm == null) this.contactBodyForm = this.contactBody;

		if (this.locationForm == null) this.locationForm = this.location;

		if (this.phoneForm == null) this.phoneForm = this.phone;

		if (this.latitudeForm == null) this.latitudeForm = this.coordinate.lat;

		if (this.longitudeForm == null) this.longitudeForm = this.coordinate.lng;

		if (this.mailForm == null) this.mailForm = this.email;

		//this.setCookieConsent();
		this.coordinate = { lat: this.latitudeForm, lng: this.longitudeForm };
		const googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=${environment?.MapApiAccessKey}&q=${this.latitudeForm},${this.longitudeForm}`;
		this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(googleMapUrl);
	}

	setCookieConsent(): void {
		// if (!(this.latitudeForm == null || this.longitudeForm == null)) {
		// 	this.coordinate = { lat: this.latitudeForm, lng: this.longitudeForm };
		// 	if (this.cookieService.get('cookieconsent_status') == 'allow') {
		// 		this.isCookieConsentAllow = true;
		// 		this.processMapData();
		// 	}
		// 	this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
		// 		if (event.status === 'allow') {
		// 			this.isCookieConsentAllow = true;
		// 			this.processMapData();
		// 		}
		// 	});
		// }
	}

	generateSingleColumnAddressHTML(address: string): string {
		const addressSegments = address.split(',');
		let outputHTML = addressSegments.join('</p><p>');
		outputHTML = `<p>${outputHTML}</p>`;
		return `<p>${outputHTML}</p>`;
	}

	ngOnDestroy() {
		this._unSubscribeAll.unsubscribe();
	}
}
