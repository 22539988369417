<ng-container *ngIf="getTranslations$ | async"></ng-container>
<div
	id="contact-us"
	class="container section-padding"
	ngClass.xs="mobile-display"
	[class.mobile-display]="isMobileView"
	[class.is-edit]="this.form?.IsOnEdit?.value"
	*ngIf="locationForm || phoneForm || mailForm || (latitudeForm && longitudeForm)"
>
	<div class="sections" fxLayout="row" fxLayout.lt-md="column">
		<div
			class="about-section"
			fxFlex="50"
			ngClass.xs="mobile-display"
			fxFlex.lt-md="100"
			*ngIf="locationForm || phoneForm || mailForm"
		>
			<div class="section-content">
				<p class="contact-text">{{ 'CONTACT' | translate }}</p>
				<h1 class="h1-48px">{{ 'HOW_CAN_REACH_COPY' | translate }}</h1>
				<p class="brief-text" [innerHTML]="contactBodyForm | translate"></p>
				<div class="contact-information">
					<h2 class="contact-info-text">{{ 'CONTACT_INFORMATION' | translate }}</h2>
					<div fxLayout="column" fxLayoutGap="18px">
						<div class="flex align-center" fxLayoutGap="16px" *ngIf="locationForm">
							<img width="24px" height="24px" src="../../../assets/icons/location_copy.svg" alt="location-icon" />
							<ng-container>
								<p [innerHTML]="locationForm"></p>
							</ng-container>
						</div>
						<div class="flex align-center" style="cursor: pointer" fxLayoutGap="16px" *ngIf="phoneForm">
							<img width="24px" height="24px" src="../../../assets/icons/phone_copy.svg" alt="phone-icon" />
							<ng-container>
								<p>
									<a href="tel:{{ phoneForm }}">{{ phoneForm }}</a>
								</p>
							</ng-container>
						</div>
						<div class="flex align-center" style="cursor: pointer" fxLayoutGap="16px" *ngIf="mailForm">
							<img width="24px" height="24px" src="../../../assets/icons/mail_copy.svg" alt="mail-icon" />
							<ng-container>
								<p>
									<a href="mailto:{{ mailForm }}">{{ mailForm?.replace('@', '[at]') }}</a>
								</p>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Right Map Section -->
		<div fxFlex="50" fxFlex.lt-md="100" class="map-section">
			<iframe
				width="100%"
				height="100%"
				style="border: 0; min-height: 400px"
				loading="lazy"
				allowfullscreen
				referrerpolicy="no-referrer-when-downgrade"
				[src]="sanitizedUrl"
			>
			</iframe>
		</div>

		<div
			*ngIf="!isCookieConsentAllow"
			(click)="onEnableMap()"
			class="privacy-wrapper"
			fxFlex
			fxLayout="column"
			fxLayoutAlign="center center"
		>
			<div class="privacy-placeholder" fxFlex="30%" fxLayout="column" fxLayoutAlign="center center">
				<div class="privacey-label">{{ 'MAP_PERMISSION' | translate }}</div>
				<button mat-flat-button class="button">{{ 'ACCEPT_BUTTON' | translate }}</button>
			</div>
		</div>
	</div>
</div>
