import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, distinctUntilChanged, takeUntil, tap } from 'rxjs';
import { environment } from '../../../../src/environments/environment';
import { AppSectionTemplateService } from '../../services/section-template.service';

@Component({
	selector: 'app-contact-us',
	templateUrl: './contact-us.component.html',
	styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
	langSub: Subscription;
	lang$: Observable<string>;

	@Input('isMobileView') isMobileView: boolean = false;
	@Input('form') form: any = null;
	// @Input('selectedLanguage') selectedLanguage: string = '';
	selectedLanguage: string = 'de';
	coordinate: any = { lat: 47.448948, lng: 8.567236 };

	contactBodyForm: any = null;
	locationForm: string = null;
	phoneForm: string = null;
	mailForm: string = null;
	latitudeForm: any = null;
	longitudeForm: any = null;
	isCookieConsentAllow: boolean = true;
	isMicrosite: boolean = false;

	private _unSubscribeAll: Subject<any>;
	private statusChangeSubscription!: Subscription;
	map: google.maps.Map;

	mapLinkText: string = '';
	locationNotFoundText: string = '';
	outletName: any;
	getTranslations$: Observable<any>;
	currentMicroSiteLanguage: string = 'de';
	sanitizedUrl: SafeResourceUrl;

	constructor(
		private sanitizer: DomSanitizer,
		private appSectionTemplateService: AppSectionTemplateService,
		private translationService: TranslateService,
		private _router: Router // private ccService: NgcCookieConsentService, // private cookieService: SsrCookieService
	) {
		this._unSubscribeAll = new Subject();
	}

	ngOnInit(): void {
		this.isMicrosite = !(
			this._router.url.includes('template-preview') ||
			this._router.url.includes('preview') ||
			this._router.url.includes('microsites')
		);
		this.getTranslations();
	}

	getTranslations(): void {
		if (this.isMicrosite) {
			this.currentMicroSiteLanguage = this.translationService.currentLang;
			this.getTranslations$ = this.translationService.get(['VIEW_IN_GOOGLE_MAPS', 'LOCATION_NOT_FOUND']).pipe(
				takeUntil(this._unSubscribeAll),
				distinctUntilChanged(),
				tap((res) => {
					this.selectedLanguage = this.currentMicroSiteLanguage;
					this.mapLinkText = res['VIEW_IN_GOOGLE_MAPS'];
					this.locationNotFoundText = res['LOCATION_NOT_FOUND'];
					this.proccessContactData();
				})
			);
			this.proccessContactData();
		} else {
			this.translationService
				.get(['VIEW_IN_GOOGLE_MAPS', 'LOCATION_NOT_FOUND'])
				.pipe(takeUntil(this._unSubscribeAll))
				.subscribe((res: string[]) => {
					this.proccessContactData();
					this.mapLinkText = res['VIEW_IN_GOOGLE_MAPS'];
					this.locationNotFoundText = res['LOCATION_NOT_FOUND'];
				});
		}
	}

	onEnableMap() {
		this.isCookieConsentAllow = true;
		//this.cookieService.set('cookieconsent_status', 'allow', 2, '/', environment.cookieDomain);
	}

	proccessContactData() {
		if (this.form != null) {
			this.outletName = this.form.OutletMetadata?.value?.OutletName || this.translationService.instant('OUTLET_NAME');
			this.selectedLanguage = this.form?.Language?.value || this.translationService.currentLang;
			this.contactBodyForm = this.form.OutletMetadata?.value?.ContactUsBodyText;
			this.locationForm = this.form.OutletMetadata?.value?.HoldingAddress;
			this.phoneForm = this.form.OutletMetadata?.value?.OutletPhoneNumber;
			this.latitudeForm = this.form.OutletMetadata?.value?.GeolocationLatitude;
			this.longitudeForm = this.form.OutletMetadata?.value?.GeolocationLongitude;
			this.mailForm = this.form?.OutletMetadata?.value?.OutletEmail;
			this.appSectionTemplateService.watchAndMarkSectionWhileEdit(this.form?.IsOnEdit, 'contact-us');
		}

		if (this._router.url.includes('template-preview')) {
			if (!this.contactBodyForm) this.contactBodyForm = 'EMAIL_OR_TELEPHONE';
			if (!this.locationForm) this.locationForm = '8058 Zurich Airport';
			if (!this.phoneForm) this.phoneForm = '079 903 46 44';
			if (!this.mailForm) this.mailForm = 'circle@sv-group.ch';
			if (!this.latitudeForm) this.latitudeForm = 47.448948;
			if (!this.longitudeForm) this.longitudeForm = 8.567236;
		}

		//this.setCookieConsent();
		this.coordinate = { lat: this.latitudeForm, lng: this.longitudeForm };
		const googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=${environment?.MapApiAccessKey}&q=${this.latitudeForm},${this.longitudeForm}`;
		this.sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(googleMapUrl);
	}

	setCookieConsent(): void {
		// if (!(this.latitudeForm == null || this.longitudeForm == null) && this.isMicrosite) {
		// 	this.coordinate = { lat: this.latitudeForm, lng: this.longitudeForm };
		// 	if (this.cookieService.get('cookieconsent_status') == 'allow') {
		// 		this.isCookieConsentAllow = true;
		// 		this.processMapData();
		// 	}
		// 	this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
		// 		if (event.status === 'allow') {
		// 			this.isCookieConsentAllow = true;
		// 			this.processMapData();
		// 		}
		// 	});
		// }
	}

	generateSingleColumnAddressHTML(address: string): string {
		const addressSegments = address.split(',');
		let outputHTML = addressSegments.join('</p><p>');
		outputHTML = `<p>${outputHTML}</p>`;
		return outputHTML;
	}

	ngOnDestroy() {
		this._unSubscribeAll.unsubscribe();
	}
}
