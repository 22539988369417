import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { SplashScreenService } from 'src/app/shared/services/splash-screen.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-root-default',
	templateUrl: './root-default.component.html',
	styleUrls: ['./root-default.component.scss'],
})
export class RootDefaultComponent implements OnInit {
	constructor(private metaService: Meta, private _splashScreenService: SplashScreenService) {
		if (environment.addNoIndexMeta) {
			this.metaService.addTag({ name: 'robots', content: 'noindex' });
		}
	}

	ngOnInit(): void {}
}
